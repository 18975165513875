import React, { useState, useEffect } from "react";
import styled, {css} from "styled-components";
import {Container} from "reactstrap";

import {media} from "utils/Media";
import {Link} from "gatsby";

import ChristmasModal from '../ChristmasModal/ChristmasModal';
import { StaticImage } from "gatsby-plugin-image";


const BookTicketsBg = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 2rem 0;

  @media ${media.md} {
    padding: 4rem 2rem 2rem;
  }
`;

const BookTicketsContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 4;
  /* max-width: 1500px; */
  .box-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    .schedules-sub-title {
      margin: 2rem 0;
      font-size: 1.85rem;
      line-height:1.85rem;
      @media ${media.md} {
        font-size: 2rem;
        line-height:2rem;
      }
    }
    .subtitle {
      font-size: 2rem;
      line-height:2rem;
      @media ${media.md} {
        font-size: 3rem;
        line-height:3.5rem;
      }
    }
    /* grid-gap: 1.5rem; */
  }
  h2, h3 {
    font-family: ${(props) => props.theme.font.family.special};
    color: ${(props) => props.theme.colors.navy};
    margin: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  h3 {
    margin-bottom: 1rem;
  }
`;
const Card = styled.div`
  width: 100%;
  padding: 7px;
  margin: 0 auto;

  .pride {
    max-width: 1200px;
    margin: 4rem 0  2rem 0;
    p {
      text-align: center;
    }
    div a {
      display: block;
      margin: 1rem auto;
    }
  }
  
  /* border-radius: 26px; */
  .btn-wrapper {
    button {
      display: block;
      margin: 0 auto;
    }
    /* display: flex;
    flex-direction: column;
    gap: 2rem;
    button {
      padding: 1rem 0;
    }
    @media ${media.md} {
      flex-direction: row;
    } */
  }
  .wrapper {
    /* border-radius: 19px; */
    padding: 1rem;
    background: #fff;
    p {
      margin: 0;
      padding: 0;
    }
    .smaller {
      font-size: 0.8rem;
    }
    .image-wrapper {
      text-align: center;
      margin: 0 auto;
      @media ${media.md} {
        display: flex;
        align-items: center;
        gap: 2rem;
        margin: 4rem auto;

      }
      .gatsby-image-wrapper {
        margin: 0 auto;
      }
    }
  }
  ${props => props.maxWidth && css`
    max-width: ${props.maxWidth}px;
  `}
  
  ${props => props.border && css`
    filter: drop-shadow(0px 0px 11px rgba(0, 49, 153, 0.6));
  background: white;
  margin: 1rem auto;
  `}
`;
const BoxWrapper = styled.div`

    margin-top: 2rem;
    @media ${media.xl} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-items: stretch;
    }
    
    .box {
        background: #fff;
        width: 100%;
        text-align: center;
        padding: 1rem;
        
        &:nth-child(1) {
            margin-bottom: 2rem;
            
            @media ${media.xl} {
                margin-bottom: 0;
            }
        }
        
        @media ${media.sm} {
            padding: 2rem;
        }
        
        @media ${media.xl} {
            width: 48%;
        }
        
        h3 {
            font-family: ${props => props.theme.font.family.special};
            color: ${props => props.theme.colors.navy};
            font-size: 24px;
            margin-bottom: 1rem;
            line-height: 100%;
            
            @media ${media.sm} {
                font-size: 30px;
            }
            
        }
        
        p {
            max-width: 450px;
        }
    }
`

const HyggeModalStyles = styled.div`
  @keyframes slide-in-bottom {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  width: 100%;
  height: 100vh;
  background: rgb(0, 49, 153, 0.25);
  z-index: 99999999999;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 3rem;
  right: 0;
  overflow-y: scroll;
  text-align: center;
  section {
    text-align: center;
    margin:  0 auto;
    overflow-y: scroll;
    animation: slide-in-bottom 0.5s 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    max-width: 400px;
    position: relative;
    width: 90%;
    padding: 2.5rem 0.5rem;
    /* height: 90%; */
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    div {
      width: 90%;
      margin: 0 auto;
      p {
        text-align: center;
        strong {
          font-size: 1.5rem;
          @media ${media.md} {
            font-size: 2rem;
          }
        }
        @media ${media.md} {
          font-size: 1.3rem;
        }
      }
      .smaller {
        font-size: 0.8rem;
      }
    }
  }
`;

const ButtonClose = styled.button`
  position: absolute;
  background-color: transparent;
  z-index: 10;
  right: 1rem;
  top: 1rem;
  width: 20px;
  height: 20px;
  border: 2px solid #81e0ff;
  cursor: pointer;

  /* @media (min-width: 992px) {
        width: 30px;
        height: 30px;
        border: 2px solid #fff;
        right: -2rem;
        top: -2rem;
    } */

  &:before {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    background-color: #81e0ff;
    transform: translate(-50%, -50%) rotate(-45deg);

    /* @media (min-width: 992px) {
            background-color: #fff;
        } */
  }

  &:after {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    background-color: #81e0ff;
    transform: translate(-50%, -50%) rotate(45deg);

    /* @media (min-width: 992px) {
            background-color: #fff;
        } */
  }
`;


const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.th`
  background: rgb(0, 49, 153, 0.85);
  text-align: left;
  padding: 8px;
  color: #fff;
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

export default function NewBookTickets({main, fourOrMore, magical, mePlusThree, christmas}) {
  const [open, setOpen] = useState(false)
  const [type, setType] = useState(false)
  const toggleModal = (type) => {
    setType(type)
    setOpen(true)
  }
  return (
    <>
    <BookTicketsBg id="ticket-information">
      <BookTicketsContent>
        <Container fluid={true} id="book-tickets">
          <section className="box-wrapper">
              <h3 className="subtitle">Frozen concludes its icy adventure in the UK! <br />
              Final performance 8 September</h3>
            
            
            {main && (
              <Card maxWidth="900">
                <div className="wrapper">


                  <h2 className="mb-3 text-center">BOOK YOUR TICKETS</h2>
                  {/* <p className="font-weight-bold text-center mb-2">
                    Winter Season now on sale!
                  </p> */}
                  {/* <a href="https://booking.lwtheatres.co.uk/event/2c62fa9a-9025-ea11-a9ca-064c84cbe4ea?promo=BIGBLOWOUT23" */}
                  <a
                    href="https://booking.lwtheatres.co.uk/event/2c62fa9a-9025-ea11-a9ca-064c84cbe4ea/"
                    className="btn btn--new btn--wide d-flex mx-auto text-uppercase"
                  >
                    <span>BOOK NOW</span>
                  </a>
                  <div className="text-center py-4">
                    <p><strong>Performance Schedule</strong></p>
                    <p>Wed 2.30pm | Thu 2.30pm & 7pm | Fri 7pm</p>
                    <p>Sat 2.30pm & 7pm | Sun 1pm & 5.30pm</p>
                    <p>Fri 26 July performance 2:30pm </p>

                    {/* <p className="mt-3 text-center smaller">From 28 February 2024: Wed, Thurs and Sat matinees 2pm | Thu – Sat 7pm | Sun 1pm & 5.30pm</p>
                    <p className=" text-center smaller">From 20 March 2024: Wed, Thurs and Sat matinees 2.30pm | Thu – Sat 7pm | Sun 1pm & 5.30pm</p> */}
                    {/* <p>Wed 2pm | Thu 2pm & 7pm | Fri 7pm</p>
                    <p>Sat 2:30pm & 7pm | Sun 1pm & 5:30pm</p> */}
  
                    {/* <p><strong>From 16 August:</strong></p> */}
                    {/* <p>Wed 7pm | Thu 2pm & 7pm | Fri 7pm</p>
                    <p>Sat 2:30pm & 7pm | Sun 1pm & 5:30pm</p> */}
                    {/* <p className="smaller mt-4">From 28 February 2024: Wed, Thurs and Sat matinees 2pm | Thu – Sat 7pm | Sun 1pm & 5.30pm</p>
                    <p className="smaller">From 20 March 2024: Wed, Thurs and Sat matinees 2.30pm | Thu – Sat 7pm | Sun 1pm & 5.30pm</p> */}
                  </div>
                  {/* <div className="image-wrapper">
                  <StaticImage src="../../images/image.png" alt=" " />
                  </div> */}
                    {/* <h3 className="schedules-sub-title">EASTER HOLIDAYS</h3>
                  <div className="btn-wrapper">
                    <button className="btn btn--new" onClick={() => toggleModal('easter')} ><span>SEE SCHEDULE</span></button>
                  </div> */}
                  {/* <div className="pride">
                  <h3 className="uppercase">Celebrate Pride in London at Frozen! </h3>
                  <p>Join us for a special performance on 28 June at 8pm. 
</p>
<div>
    <Link to="/pride/" className="btn btn--new pride-grad">
      <span>BOOK TICKETS</span>
    </Link>
    </div>
                  </div> */}
                </div>
              </Card>
            )}
                {christmas && (
                 <Card border maxWidth="500">
                 <div className="wrapper">
                     <h3>View Christmas Schedule</h3>
                     <button
                         onClick={() => setOpen(true)}
                         className="btn btn--new d-flex mx-auto text-uppercase"><span>See schedule</span></button>
                 </div>
             </Card>
            )}
            {/* {fourOrMore && (
              <Card maxWidth="750" border>
                <div className="wrapper">
                  
                  <h3>Book 4 or More!</h3>
                  <p className="text-center color--black ">FROZEN is better with friends and family! Book 4 or more
                    tickets and save up to 44% on Stalls or Royal Circle tickets.</p>
                  <p className="text-center color--black ">Please see terms and conditions for full details and
                    exclusion periods.</p>
                  <Link
                    to="/four-or-more/"
                    className="btn btn--new btn--wide d-flex mx-auto mt-3 text-uppercase"><span>Book 4 or More</span></Link>
                  <p className="mt-4 text-center color--black ">Book by 27 May for select performances through 14 July. T&Cs apply.</p>
                  
                  <p className="text-center color--black mt-4 ">Need more than 8 tickets? Learn about our 9+ Group
                    Rates <Link to="/groups/">here</Link>.</p>
                </div>
              </Card>
            )} */}
            {magical && (
              <Card maxWidth="500" border>
                <div className="wrapper">
                  <h3>Magical Mondays</h3>
                  <p className="text-center color--black ">£29.50 tickets* released every Monday
                    for that week’s performances.</p>
                  <a
                    href="https://www.disneytickets.co.uk/disney-day-seats-tickets?adobe_mc=MCMID%3D05611510943531079091725521094975638646%7CMCORGID%3DCC0A3704532E6FD70A490D44%2540AdobeOrg%7CTS%3D1657287705"
                    className="btn btn--new btn--wide d-flex mx-auto mt-3 no-interstitial-check text-uppercase"><span>MORE INFORMATION</span></a>
                  <p className="mt-1 small lineHeightSmaller text-center">*Maximum 4 per
                    transaction</p>
                </div>
              </Card>
            )}
            {mePlusThree && (
              <Card maxWidth="750" border>
                <div className="wrapper">
                  <h3>Me+3</h3>
                  <p className="text-center color--black ">FROZEN is better with friends and family! For a limited time,
                    take advantage of our Me+3 offer and buy 4 tickets for £199, valid at selected performances. Buy for
                    yourself and take 3 friends!</p>
                  <p className="text-center color--black ">Please see terms and conditions for full details and
                    exclusion periods.</p>
                  <a
                    href="https://booking.lwtheatres.co.uk/event/2c62fa9a-9025-ea11-a9ca-064c84cbe4ea?promo=meplusthree"
                    className="btn btn--new btn--wide d-flex mx-auto mt-3 text-uppercase"><span>BOOK ME+3 TICKETS</span></a>
                  <p className="mt-1 small lineHeightSmaller text-center">This offer is valid for selected performances
                    until 15 October 2023. Visit our website for full terms and conditions.</p>
                </div>
              </Card>
            )}
        
          </section>
        </Container>
      </BookTicketsContent>
    </BookTicketsBg>
    <Modal type={type} open={open} setOpen={setOpen} />
    {/* <ChristmasModal open={open} setOpen={setOpen}/> */}
    </>
  );
}


const Modal = ({ type, open, setOpen }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);
  return  open ? (
    <HyggeModalStyles onClick={() => setOpen(!open)}>
      <section>
        <ButtonClose onClick={() => setOpen(!open)}>
          <span className="sr-only">Close</span>
        </ButtonClose>
        <Table>
      <thead>
        <tr>
          <TableHeader>Date</TableHeader>
          <TableHeader>Time</TableHeader>
        </tr>
      </thead>
    {type === 'half-term' && (
        <tbody>
        <tr>
          <TableCell>Wed 14 Feb</TableCell>
          <TableCell>2pm & 7pm</TableCell>
        </tr>
        <tr>
          <TableCell>Thu 15 Feb</TableCell>
          <TableCell>2pm & 7pm</TableCell>
        </tr>
        <tr>
          <TableCell>Fri 16 Feb</TableCell>
          <TableCell>2pm</TableCell>
        </tr>
        <tr>
          <TableCell>Sat 17 Feb</TableCell>
          <TableCell>2.30pm & 7pm</TableCell>
        </tr>
        <tr>
          <TableCell>Sun 18 Feb</TableCell>
          <TableCell>1pm & 5.30pm</TableCell>
        </tr>
      </tbody>
    )}
    {type === 'easter' && (
         <tbody>
         <tr>
           <TableCell>Wed 27 Mar</TableCell>
           <TableCell>2.30pm</TableCell>
         </tr>
         <tr>
           <TableCell>Thu 28 Mar</TableCell>
           <TableCell>2.30pm & 7pm</TableCell>
         </tr>
         <tr>
           <TableCell>Fri 29 Mar</TableCell>
           <TableCell>2.30pm</TableCell>
         </tr>
         <tr>
           <TableCell>Sat 30 Mar</TableCell>
           <TableCell>2.30pm & 7pm</TableCell>
         </tr>
         <tr>
           <TableCell>Sun 31 Mar</TableCell>
           <TableCell>1pm & 5.30pm</TableCell>
         </tr>
       </tbody>
    )}
    </Table>
      </section>
    </HyggeModalStyles>
  ) : (
    ""
  );
}