import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { media } from "utils/Media";

const HyggeModalStyles = styled.div`
  @keyframes slide-in-bottom {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  width: 100%;
  height: 100vh;
  background: rgb(0, 49, 153, 0.25);
  z-index: 99999999999;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 3rem;
  right: 0;
  overflow-y: scroll;
  text-align: center;
  section {
    text-align: center;
    margin:  0 auto;
    overflow-y: scroll;
    animation: slide-in-bottom 0.5s 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    max-width: 400px;
    position: relative;
    width: 90%;
    padding: 2.5rem 0.5rem;
    /* height: 90%; */
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    div {
      width: 90%;
      margin: 0 auto;
      p {
        text-align: center;
        strong {
          font-size: 1.5rem;
          @media ${media.md} {
            font-size: 2rem;
          }
        }
        @media ${media.md} {
          font-size: 1.3rem;
        }
      }
      .smaller {
        font-size: 0.8rem;
      }
    }
  }
`;

const ButtonClose = styled.button`
  position: absolute;
  background-color: transparent;
  z-index: 10;
  right: 1rem;
  top: 1rem;
  width: 20px;
  height: 20px;
  border: 2px solid #81e0ff;
  cursor: pointer;

  /* @media (min-width: 992px) {
        width: 30px;
        height: 30px;
        border: 2px solid #fff;
        right: -2rem;
        top: -2rem;
    } */

  &:before {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    background-color: #81e0ff;
    transform: translate(-50%, -50%) rotate(-45deg);

    /* @media (min-width: 992px) {
            background-color: #fff;
        } */
  }

  &:after {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    background-color: #81e0ff;
    transform: translate(-50%, -50%) rotate(45deg);

    /* @media (min-width: 992px) {
            background-color: #fff;
        } */
  }
`;


const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.th`
  background: rgb(0, 49, 153, 0.85);
  text-align: left;
  padding: 8px;
  color: #fff;
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

const ChristmasModal = ({ toggleChristmasModal, christmasModalOpen, open ,setOpen}) => {
  useEffect(() => {
    if (christmasModalOpen) {
      document.body.style.overflow = "hidden";
    } else if (!christmasModalOpen) {
      document.body.style.overflow = "auto";
    }
  }, [christmasModalOpen]);
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else if (!open) {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  const toggle = () => {
    if (christmasModalOpen) {
      toggleChristmasModal(false)
    }else {
      setOpen(!open)
  }
  }
  return christmasModalOpen || open ? (
    <HyggeModalStyles onClick={() => toggle()}>
      <section>
        <ButtonClose onClick={() => toggle()}>
          <span className="sr-only">Close</span>
        </ButtonClose>
        <Table>
      <thead>
        <tr>
          <TableHeader>Date</TableHeader>
          <TableHeader>Time</TableHeader>
        </tr>
      </thead>
      <tbody>
        <tr>
          <TableCell>Wed 20 Dec</TableCell>
          <TableCell>7pm</TableCell>
        </tr>
        <tr>
          <TableCell>Thu 21 Dec</TableCell>
          <TableCell>2pm & 7pm</TableCell>
        </tr>
        <tr>
          <TableCell>Fri 22 Dec</TableCell>
          <TableCell>2pm & 7pm</TableCell>
        </tr>
        <tr>
          <TableCell>Sat 23 Dec</TableCell>
          <TableCell>1pm & 5.30pm</TableCell>
        </tr>
        <tr>
          <TableCell>Sun 24 Dec</TableCell>
          <TableCell>1pm</TableCell>
        </tr>
        <tr>
          <TableCell>Mon 25 Dec</TableCell>
          <TableCell>no show (Christmas Day)</TableCell>
        </tr>
        <tr>
          <TableCell>Tue 26 Dec</TableCell>
          <TableCell>no show (Boxing Day)</TableCell>
        </tr>
        <tr>
          <TableCell>Wed 27 Dec</TableCell>
          <TableCell>5.30pm</TableCell>
        </tr>
        <tr>
          <TableCell>Thu 28 Dec</TableCell>
          <TableCell>2pm & 7pm</TableCell>
        </tr>
        <tr>
          <TableCell>Fri 29 Dec</TableCell>
          <TableCell>2pm & 7pm</TableCell>
        </tr>
        <tr>
          <TableCell>Sat 30 Dec</TableCell>
          <TableCell>1pm & 5.30pm</TableCell>
        </tr>
        <tr>
          <TableCell>Sun 31 Dec</TableCell>
          <TableCell>1pm</TableCell>
        </tr>
        <tr>
          <TableCell>Mon 1 Jan</TableCell>
          <TableCell>no show (New Year’s Day)</TableCell>
        </tr>
        <tr>
          <TableCell>Tue 2 Jan</TableCell>
          <TableCell>no show</TableCell>
        </tr>
        <tr>
          <TableCell>Wed 3 Jan</TableCell>
          <TableCell>2pm</TableCell>
        </tr>
        <tr>
          <TableCell>Thu 4 Jan</TableCell>
          <TableCell>2pm & 7pm</TableCell>
        </tr>
        <tr>
          <TableCell>Fri 5 Jan</TableCell>
          <TableCell>7pm</TableCell>
        </tr>
        <tr>
          <TableCell>Sat 6 Jan</TableCell>
          <TableCell>1pm & 5.30pm</TableCell>
        </tr>
        <tr>
          <TableCell>Sun 7 Jan</TableCell>
          <TableCell>1pm & 5.30pm</TableCell>
        </tr>
      </tbody>
    </Table>
      </section>
    </HyggeModalStyles>
  ) : (
    ""
  );
};

export default ChristmasModal;
